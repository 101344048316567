.zoom-enter,
.zoom-appear {
  opacity: 0;
  transform: scale(0.9);
}
.zoom-enter-active,
.zoom-appear-active {
  opacity: 1;
  transition: opacity 500ms, transform 500ms;
}
.zoom-exit {
  opacity: 1;
}
.zoom-exit-active {
  opacity: 0;
  // transform: scale(0.9);
  transition: opacity 500ms;
}

.fade-enter,
.fade-appear {
  opacity: 0;
}
.fade-enter-active,
.fade-appear-active {
  opacity: 1;
  transition: opacity 300ms;
}
.fade-exit {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
  transition: opacity 300ms;
}
